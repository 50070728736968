import React from 'react';
import { Container } from './styles';

const Foot = () => {
    return (
        <Container>
            <p>@Copyright Phellaty</p>
        </Container>
    )
}

export default Foot;