import React from 'react';
import { Container } from './styles';

const Help = () => {
    return (
        <>
            <Container>
                <p>Se fode ai</p>
            </Container>
        </>
    )
}

export default Help;